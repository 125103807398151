import { getCurrencyCode, queryKey, type Merge, type Timestamp } from '@segunosoftware/equinox';
import type { CurrencyCode } from '@shopify/react-i18n';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ACCOUNT } from './query-keys';
import type { Get, Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';
export type ReviewStatus = 'ignored' | 'reviewed' | 'denied' | 'deferred';
export type ShopPlan =
	| 'basic'
	| 'professional'
	| 'unlimited'
	| 'shopify_plus'
	| 'plus_partner_sandbox'
	| 'partner_test'
	| 'affiliate'
	| 'frozen'
	| 'cancelled'
	| 'paused'
	| 'staff'
	| 'staff_business'
	| 'trial'
	| 'fraudulent'
	| 'npo_full'
	| 'npo_lite'
	| 'business'
	| 'sales_training'
	| 'comped'
	| 'dormant'
	| 'custom'
	| 'enterprise'
	| 'starter'
	| 'shopify_alumni'
	| 'open_learning'
	| 'grandfather';

export type Account = {
	id: number;
	userHash: string;
	supportUserHash: string;
	name?: string;
	publicId?: string;
	ownerName?: string;
	ownerEmail?: string;
	phone?: string;
	shop: string;
	primaryLocale: string;
	currency: CurrencyCode;
	timezone: string;
	countryCode: string;
	domain?: string;
	platformPlan?: ShopPlan;
	billingStatus?: AccountBillingStatus;
	basePrice: number;
	monthlyImportedCodes: number;
	totalImportedCodes: number;
	discountSetsAllowed: number;
	discountSetsCreated: number;
	freePlan: boolean;
	reviewRating: number;
	reviewStatus: ReviewStatus;
	createdAt: Date;
	updatedAt: Date;
};

export type DehydratedAccount = Merge<
	Account,
	{
		currency?: string;
		createdAt: Timestamp;
		updatedAt: Timestamp;
	}
>;

function hydrateAccount(account: DehydratedAccount): Account;
function hydrateAccount(account: DehydratedAccount | undefined): Account | undefined {
	return account
		? {
				...account,
				currency: getCurrencyCode(account.currency),
				createdAt: new Date(account.createdAt),
				updatedAt: new Date(account.updatedAt)
			}
		: undefined;
}

function useSetAccount() {
	const queryClient = useQueryClient();
	return useCallback(
		(account: DehydratedAccount) => {
			if (account) {
				queryClient.setQueryData(queryKey(ACCOUNT), hydrateAccount(account));
			}
		},
		[queryClient]
	);
}

// only use this to do the initial account loading
export function useAccountLoader(enabled = true) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedAccount>;
	const query = useQuery({
		queryKey: queryKey(ACCOUNT),
		queryFn: () => get('/auth/whoami').then(hydrateAccount),
		enabled
	});
	return { account: query.data, isLoadingAccount: query.isFetching };
}

export function useAccount() {
	const { account } = useAccountLoader(false); // enabled is false here because someone else must load the account for us

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

export function useReviewRating() {
	const { post } = useAuthenticatedFetch() as Post<any, DehydratedAccount>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const { mutate: setReviewRating, isPending: isSetReviewRatingLoading } = useMutation({
		mutationFn: (reviewRating: number) => post('/account/review-rating', { rating: reviewRating }),
		...setAccountOption
	});

	const { mutate: setReviewStatus, isPending: isSetReviewStatusLoading } = useMutation({
		mutationFn: (reviewStatus: ReviewStatus) => post('/account/review-status', { reviewStatus }),
		...setAccountOption
	});

	return {
		setReviewRating: (reviewRating: number) => setReviewRating(reviewRating),
		isSetReviewRatingLoading,
		setReviewStatus: (reviewStatus: ReviewStatus) => setReviewStatus(reviewStatus),
		isSetReviewStatusLoading
	};
}
